import React from "react"
import Image from "./Image"
import { Phone } from "react-feather"
import { useCookies } from "react-cookie"

const PersonContact = () => {
  const [cookies] = useCookies()
  const mzCookies = cookies.maklerzentrale || {}
  return (
    <div className="row d-flex align-items-center">
      <div className="col-md-6 order-md-2">
        <h2>Wir beraten Sie gerne</h2>
        <p className="text-muted">
          Rufen Sie uns an, schreiben Sie uns eine E-Mail oder kommen Sie direkt
          in unser Beratungsbüro in Mainz. Gerne vereinbaren wir einen
          persönlichen Termin mit Ihnen. Sprechen Sie uns an. Wir freuen uns auf
          Sie!
        </p>
        <hr className="d-none d-lg-block border-gray-300" />
        <h3>Ihr Kontakt zu uns</h3>
        {/*<h4 className="fw-bold mb-0">Heike Cipolla</h4>*/}
        {/*<p className="text-muted mb-0">Abteilungsleitung Kundenbetreuung</p>*/}
        <p className="mb-5">
          <Phone size={18} className="me-1" />{" "}
          <a href="tel:+498002242020" className="h4">
            0800 2242020
          </a>
        </p>

        <a
          href="#chat"
          onClick={() =>
            window &&
            ((!mzCookies.thirdParty &&
              alert(
                'Wir nehmen Datenschutz ernst.\n\nBitte erlauben Sie unsere Cookies oder "Dienste von anderen Anbietern" unter den "Cookie-Einstellungen" in der Datenschutzerklärung um den Chat zu aktivieren.\n\n\n'
              )) ||
              (window.$crisp && window.$crisp.do("chat:open")))
          }
          className="btn btn-sm btn-primary py-3 px-3 me-2"
        >
          Jetzt im Chat!
        </a>
        <a
          href="mailto:info@maklerzentrale.com"
          className="btn btn-sm btn-primary-desat-soft py-3 px-3"
          style={{ minWidth: "133px" }}
        >
          E-Mail
        </a>
      </div>
      <div className="col-md-6 order-md-1 py-4 py-md-8 px-0">
        <Image
          src="Telefon-Mitarbeiter2.png"
          alt="Kontakt Maklerzentrale"
          style={{ maxWidth: "350px" }}
          imgStyle={{ objectFit: "contain" }}
          className="img-fluid mx-auto mt-5 mt-md-0"
        />
        {/*<Image*/}
        {/*  src="heike_cipolla.png"*/}
        {/*  alt="Kontakt Dame Maklerzentrale"*/}
        {/*  style={{ maxWidth: "550px" }}*/}
        {/*  imgStyle={{ objectFit: "contain" }}*/}
        {/*  className="img-fluid mx-auto mt-5 mt-md-0"*/}
        {/*/>*/}
      </div>
    </div>
  )
}

export default PersonContact
