import React, { useContext, useEffect } from "react"
import HelmetHead from "../../components/HelmetHead"
import Loadable from "@loadable/component"
import Image from "../../components/Image"
import Curve1 from "../../svgs/shapes/curves/curve-1.svg"
import { ArrowDown, ChevronRight } from "react-feather"
const RatingSlider = Loadable(() => import("../../components/RatingSlider"))
import BackgroundSection from "../../components/BackgroundSection"
import FileDone from "../../svgs/icons/duotone-icons/Files/File-done.svg"
import PenToolVector from "../../svgs/icons/duotone-icons/Design/Pen-tool-vector.svg"
import User from "../../svgs/icons/duotone-icons/General/User.svg"
import ChatError from "../../svgs/icons/duotone-icons/Communication/Chat-error.svg"
import PersonContact from "../../components/PersonContact"
import smoothScroll from "../../components/smoothScroll"
import LayoutContext from "../../components/LayoutContext"
import { Link } from "gatsby"

const LoadableIntroVideo = Loadable(() => import("../../components/IntroVideo"))

const UeberUns = () => {
  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {},
    }))
  }, [])

  return (
    <>
      <HelmetHead title="Über die Maklerzentrale" />
      <BackgroundSection
        src="/images/custom/lg/UeberMaklerzentrale2.jpg"
        className="bg-cover"
        style={{ minHeight: "70vh" }}
      >
        <div className="container" />
      </BackgroundSection>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
          <Curve1 />
        </div>
      </div>

      <section className="py-7 py-md-9">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="scroll-beacon" id="ueber-uns" />
              <a
                href="#ueber-uns"
                className="btn btn-white btn-rounded-circle shadow mt-n11 mt-md-n13"
                onClick={smoothScroll}
              >
                <ArrowDown size={20} />
              </a>
            </div>
          </div>
          {/*<p className="h3 mb-4">*/}
          {/*  /!*Herzlich willkommen bei der Maklerzentrale für Deutschland*!/*/}
          {/*  Ihr starker Partner von Anfang an*/}
          {/*</p>*/}
          <h1 className="display-4">Über die Maklerzentrale</h1>
          <p className="text-muted text-justify">
            Die Maklerzentrale ist eine <strong>erste Anlaufstelle</strong> für
            anspruchsvolle Eigentümer, die mehr über die wirtschaftlichen
            Möglichkeiten ihrer Immobilie erfahren möchten. Unsere
            Immobilienexperten{" "}
            <strong>beraten Eigentümer rund um ihre Immobilie</strong>, sei es
            auf der Website mit einschlägigen Ratgebern oder im{" "}
            <strong>telefonischen Gespräch</strong>. Mit dem{" "}
            <strong>Netzwerk aus Immobilienmaklern</strong> und Sachverständigen
            bieten wir die Beratung auch bei einem{" "}
            <strong>persönlichen Besichtigungstermin</strong> vor Ort an. Damit
            können wir fast jedes Anliegen unserer Kunden lösen.
          </p>

          <p className="text-muted text-justify mb-0">
            Steht ein Kauf oder Verkauf einer Immobilie an, kommen wichtige
            Fragen, Überlegungen und grundlegende Entscheidungen auf Sie zu:
            Lohnt sich jetzt ein Hausverkauf? <strong>Welchen Preis</strong>{" "}
            können Sie erzielen? <strong>Welche Unterlagen</strong> benötigen
            Sie und wo beschaffen Sie sie? Was gilt es zu beachten? Jeder Fall
            ist individuell und erfordert{" "}
            <strong>Fachwissen und Erfahrung</strong>, um die richtigen
            Entscheidungen zu treffen. Gemeinsam mit{" "}
            <strong>unseren Partnern</strong> begleiten wir Sie auf dem Weg zum
            Kauf oder Verkauf einer Immobilie. Der Service und die Beratung der
            Maklerzentrale sind <strong>kostenfrei und unverbindlich</strong>.
          </p>
          <div className="row align-items-center py-6 py-md-8">
            <div className="col-12 col-md-7">
              <div
                className="mb-8 mb-md-0"
                data-sal="slide-right"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Image
                  src="simon-kuhlmann-marcel-amberge-wohngold-immobilien2.jpg"
                  imgStyle={{ objectFit: "contain" }}
                  className="screenshot img-fluid mw-110 float-end me-md-6 mb-6 mb-md-0 w-100 w-md-110"
                  alt="Wohngold Immobilien"
                />
              </div>
            </div>
            <div className="col-12 col-md-5">
              <h3 className="fw-bold">
                Simon Kuhlmann, M.A.
                <br />
                Marcel Amberge, M.A.
              </h3>

              <p className="text-muted mb-0">Wohngold Immobilien, Köln</p>
            </div>
          </div>
          {/*<div className="py-5">*/}
          {/*  <div*/}
          {/*    className=""*/}
          {/*    data-sal="fade"*/}
          {/*    data-sal-delay="200"*/}
          {/*    data-sal-duration="700"*/}
          {/*  >*/}
          {/*    <Image*/}
          {/*      src="simon-kuhlmann-marcel-amberge-wohngold-immobilien2.jpg"*/}
          {/*      style={{ maxWidth: "700px" }}*/}
          {/*      className=""*/}
          {/*      alt="Wohngold Immobilien"*/}
          {/*    />*/}
          {/*    <span className="fs-sm font-italic">*/}
          {/*      Simon Kuhlmann und Marcel Amberge von Wohngold Immobilien, Köln*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <p className="text-muted text-justify mb-0">
            Das Netzwerk aus Immobilienmaklern und Sachverständigen wurde 2017
            u.a. von <strong>Simon Kuhlmann und Marcel Amberge</strong> von
            Wohngold Immobilien in Köln ins Leben gerufen. Als{" "}
            <strong>Dozent für Immobilienwirtschaft</strong> an der Hochschule
            Fresenius und der CBS International Business School Köln kennt Herr
            Kuhlmann die Bedürfnisse und Herausforderungen, mit denen tausende
            Eigentümer konfrontiert werden. Mit{" "}
            <strong>Leidenschaft und zielstrebigem Engagement</strong> finden
            die Immobilienexperten täglich Antworten auf die Fragen der
            Eigentümer und richten sich stets nach Ihren Wünschen, um eine{" "}
            <strong>langjährige Verbundenheit</strong> zu pflegen. Seit 2020
            leiten Tobias Brauchle und Albino Cipolla das Tagesgeschäft und
            übernehmen die Verantwortung für den Service und die Qualität der
            Maklerzentrale. Weitere Bereiche in denen wir unsere Kunden
            unterstützen sind Baufinanzierungen und Bonitätsprüfungen,
            Bauanträge über Architekturbüros sowie{" "}
            <strong>Streitschlichtung und Immobilienmediation</strong> durch
            erfahrene Spezialisten.
          </p>
          {/*<div className="row align-items-center py-5 border-top border-bottom">*/}
          {/*  <div*/}
          {/*    className="col"*/}
          {/*    data-sal="fade"*/}
          {/*    data-sal-delay="200"*/}
          {/*    data-sal-duration="700"*/}
          {/*  >*/}
          {/*    <Image*/}
          {/*      src="/assets/logo/maklerzentrale_fw.png"*/}
          {/*      loadFrom="logo"*/}
          {/*      style={{ maxWidth: "510px" }}*/}
          {/*      className="mx-auto"*/}
          {/*      alt="Maklerzentrale"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </section>

      {/*<section>*/}
      {/*  <div className="container">*/}
      {/*    <div className="row align-items-center py-6 py-md-8">*/}
      {/*      <div className="col-12 col-sm-6">*/}
      {/*        <div*/}
      {/*          className="mb-8"*/}
      {/*          data-sal="slide-left"*/}
      {/*          data-sal-delay="200"*/}
      {/*          data-sal-duration="700"*/}
      {/*        >*/}
      {/*          <Image*/}
      {/*            src="albino_cipolla_bg.jpg"*/}
      {/*            className="mx-auto rounded"*/}
      {/*            alt="Albino Salvatore Cipolla"*/}
      {/*            style={{ maxWidth: "250px" }}*/}
      {/*          />*/}
      {/*        </div>*/}

      {/*        <h3 className="fw-bold">*/}
      {/*          Albino Salvatore Cipolla, M.A.*/}
      {/*        </h3>*/}
      {/*        <p className="fs-lg text-muted mb-6 mb-md-0">*/}
      {/*          Geschäftsführer*/}
      {/*          /!*(Maklerzentrale &{" "}*!/*/}
      {/*          /!*<a href="https://www.opernreisefuehrer.de/" target="_blank">*!/*/}
      {/*          /!*  Opernreiseführer.de*!/*/}
      {/*          /!*</a>*!/*/}
      {/*          /!*)*!/*/}
      {/*          <br />*/}
      {/*          Leitung Qualitätsmanagement*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*      <div className="col-12 col-sm-6">*/}
      {/*        <div*/}
      {/*          className="mb-8"*/}
      {/*          data-sal="slide-right"*/}
      {/*          data-sal-delay="200"*/}
      {/*          data-sal-duration="700"*/}
      {/*        >*/}
      {/*          <Image*/}
      {/*            src="tobias_brauchle_bg.jpg"*/}
      {/*            className="mx-auto rounded"*/}
      {/*            alt="Tobias Brauchle"*/}
      {/*            style={{ maxWidth: "250px" }}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*        <h3 className="fw-bold">Tobias Brauchle, B.Eng.</h3>*/}
      {/*        <p className="fs-lg text-muted mb-6 mb-md-0">*/}
      {/*          Geschäftsführer (Maklerzentrale &{" "}*/}
      {/*          <a href="https://leadmarkt.ch/" target="_blank">*/}
      {/*            Leadmarkt.ch*/}
      {/*          </a>*/}
      {/*          )*/}
      {/*          <br />*/}
      {/*          Leitung Softwareentwicklung*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      {/*<section*/}
      {/*  className="pt-8 pt-md-11"*/}
      {/*  style={{*/}
      {/*    background:*/}
      {/*      "linear-gradient(0deg, rgba(241,244,248,1) 0%, rgba(255,255,255,1) 100%)",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div className="container">*/}
      {/*    <h2 className="fw-bold">Unsere Arbeit</h2>*/}
      {/*    <p className="text-muted">*/}
      {/*      Die Maklerzentrale prüft unabhängig zertifizierte Makler, Gutachter*/}
      {/*      und Finanzdienstleister.*/}
      {/*    </p>*/}
      {/*    <p className="text-muted">*/}
      {/*      <ChevronRight size={17} className="align-middle text-primary" /> Wir*/}
      {/*      finden den passenden Makler zu Ihrer Immobilie.*/}
      {/*    </p>*/}
      {/*    <p className="text-muted">*/}
      {/*      <ChevronRight size={17} className="align-middle text-primary" /> Wir*/}
      {/*      vertreten Verkäuferinteressen auf kommunaler und landesweiter Ebene.*/}
      {/*    </p>*/}
      {/*    <p className="text-muted">*/}
      {/*      <ChevronRight size={17} className="align-middle text-primary" /> Wir*/}
      {/*      informieren Medien und Öffentlichkeit über wichtige Themen.*/}
      {/*    </p>*/}
      {/*    <p className="text-muted">*/}
      {/*      <ChevronRight size={17} className="align-middle text-primary" /> Wir*/}
      {/*      führen verkäuferrelevante Aktionen, Projekte und Ausstellungen*/}
      {/*      durch.*/}
      {/*    </p>*/}

      {/*    <div className="row justify-content-center py-6">*/}
      {/*      <div className="col-12 col-md-10 col-lg-7">*/}
      {/*        <div className="card overflow-hidden shadow-lg">*/}
      {/*          <LoadableIntroVideo />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <h2 className="fw-bold mt-8">Kosten</h2>*/}
      {/*    <p className="text-muted m-0">*/}
      {/*      Unser Service wird von unseren Partnern finanziert und bleibt für*/}
      {/*      Eigentümer in jedem Fall 100% kostenfrei. Profitieren Sie von*/}
      {/*      unserer langjährigen Erfahrung mit erstklassigen Immobilienmaklern.*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</section>*/}

      <section
        className="pt-7 bg-gray-200"
        // style={{ backgroundColor: "#eeefee" }}
      >
        <div className="container">
          <PersonContact />
        </div>

        <div className="bg-dark">
          <RatingSlider className="pt-8 pb-2" />
        </div>
      </section>

      <section
        className="py-7 py-md-9"
        style={{
          background:
            "linear-gradient(0deg, rgba(241,244,248,1) 0%, rgba(255,255,255,1) 100%)",
        }}
      >
        <div className="container">
          <div className="scroll-beacon" id="philosophie" />
          <h2 className="fw-bold">Unsere Philosophie</h2>
          <p className="text-muted mb-8">
            Die Maklerzentrale vertritt die Interessen von Immobilieneigentümern
            gegenüber Maklerunternehmen in{" "}
            <u>
              <Link to="/de/immobilienpreise-deutschland/">Deutschland</Link>
            </u>
            ,{" "}
            <u>
              <Link to="/de/immobilienmakler-oesterreich/">Österreich</Link>
            </u>{" "}
            und der{" "}
            <u>
              <Link to="/de/immobilienmakler-schweiz/">Schweiz</Link>
            </u>
            . Um dies langfristig optimal umzusetzen ist ein Höchstmaß an
            Integrität und Vertrauen erforderlich. Wir trennen die Spreu vom
            Weizen. Ehrlichkeit und Aufrichtigkeit sind unsere höchsten
            Grundwerte, nach denen wir Maklerunternehmen und Mitarbeiter
            auswählen. Von diesem Vertrauen hängt der Erfolg unseres
            Unternehmens ab.
          </p>

          <div className="row">
            <div className="col-12 col-lg-6">
              <div
                className="card card-border shadow-light-lg mb-6 mb-md-8 lift lift-lg"
                style={{ borderTopColor: "#4ac25a" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2 ">
                    <div>
                      <h3 className="fw-bold mb-0">
                        Integrität und Aufrichtigkeit
                      </h3>
                    </div>
                    <div className="icon text-primary ms-5">
                      <FileDone />
                    </div>
                  </div>
                  <p className="text-muted">
                    Wir handeln in dem Bewusstsein, dass sich unsere hohen
                    Maßstäbe und Wertvorstellungen stets in unserem Verhalten
                    und in der Auswahl unserer Maklerempfehlungen
                    wiederspiegeln. Integrität verstehen wir als Treue zu
                    unseren Grundsätzen und darüber hinaus als Achtung unserer
                    Partner.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="card card-border shadow-light-lg mb-6 mb-md-8 lift lift-lg"
                style={{ borderTopColor: "#153f91" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2 ">
                    <div>
                      <h3 className="fw-bold mb-0">
                        Vertrauen und Ehrlichkeit
                      </h3>
                    </div>
                    <div className="icon text-primary ms-5">
                      <User />
                    </div>
                  </div>
                  <p className="text-muted">
                    Wir sind ein verlässlicher Partner, erfüllen unsere
                    Verpflichtungen und übernehmen persönlich Verantwortung für
                    unser Handeln. Wir versprechen nur, was wir halten können.
                  </p>
                  <br className="d-none d-lg-inline" />
                  <br className="d-none d-lg-inline" />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="card card-border shadow-light-lg mb-6 mb-md-8 lift lift-lg"
                style={{ borderTopColor: "#ff0000" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2 ">
                    <div>
                      <h4 className="fw-bold mb-0">
                        Diskretion und Anonymität
                      </h4>
                    </div>
                    <div className="icon text-primary ms-5">
                      <ChatError />
                    </div>
                  </div>
                  <p className="text-muted">
                    Vertrauliche Informationen werden auch als solche behandelt.
                    Wir geben keine persönlichen Daten weiter, die unser
                    Unternehmen, unsere Kunden oder unsere Mitarbeiterinnen und
                    Mitarbeiter betreffen, außer dies ist ausdrücklich erwünscht
                    oder gesetzlich erforderlich. Weitere Informationen
                    entnehmen Sie unseren Datenschutzbestimmungen.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="card card-border shadow-light-lg lift lift-lg"
                style={{ borderTopColor: "#ffb900" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2 ">
                    <div>
                      <h3 className="fw-bold mb-0">Fairness und Transparenz</h3>
                    </div>
                    <div className="icon text-primary ms-5">
                      <PenToolVector />
                    </div>
                  </div>
                  <p className="text-muted">
                    Wir behandeln unsere Geschäftspartner höflich und
                    respektvoll. Wir fördern Chancengleichheit und ein
                    Arbeitsumfeld, das frei ist von Diskriminierung und
                    Belästigungen jeder Art ist. Mit unseren Geschäfts­partnern
                    pflegen wir einen konstruktiven, transparenten und offenen
                    Dialog, der von Fairness, Respekt und Professionalität
                    getragen wird.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
          <Curve1 />
        </div>
      </div>

      <section className="bg-white">{/*Platzhalter Curve*/}</section>
    </>
  )
}

export default UeberUns
